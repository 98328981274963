<template>
  <div class="companynews">
    <div class="line" />
    <h2>行业新闻</h2>
    <div class="news-wrapper">
      <div
        v-for="(item, index) in newLists"
        :key="index"
        class="news-item"
        @click="JumpNews(item.newsId)"
      >
        <div class="news-img">
          <img :src="item.img" width="259" height="200" alt="">
        </div>
        <div class="news-content">
          <div class="news-date">
            <span>{{ item.date.substring(8,10) }}</span>
            <span />
            <span>{{ item.date.substring(5,7) }}</span>
            <span>{{ item.date.substring(0,4) }}</span>
          </div>
          <div class="news-line" />
          <div class="news-right">
            <h2>{{ item.title }}</h2>
            <p v-html="item.infor" />
          </div>
        </div>
      </div>
    </div>

    <div class="next-button">
      <div class="page-pagination">
        <el-pagination
          :hide-on-single-page="pageValue"
          background
          :current-page="currentPage"
          layout="total, prev, pager, next, sizes, jumper"
          :total="total"
          :page-sizes="[10, 20, 50]"
          :page-size="params.pageSize"
          prev-text="<"
          next-text=">"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
      <!-- <span class="icon-wrap-left">
        <i class="el-icon-arrow-left" />
      </span>
      <span class="icon-wrap-right">
        <i class="el-icon-arrow-right" />
      </span> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      params: {
        pageIndex: 1,
        pageSize: 10,
        orderBy: '',
        sort: ''
      },
      currentPage: 1,
      total: 0,
      pageValue: false,
      newLists: [
        // { date: '2022-02-16', img: require('../../assets/img/hang-s4.jpg'), title: '《网络安全审查办法》今起生效，互联网及网络货运企业境外上市，如何合规？', infor: '2月15日起，由国家互联网信息办公室等十三部门联合修订了《网络安全审查办法》正式施行。网络安全监管新规下，互联网企业（以网络货运企业为例）——境外融资如何做好合规应对？', content: ``, newsId: 104 },
        // { date: '2022-02-15', img: require('../../assets/img/hang-s3.jpg'), title: '网络货运发展状况，政策梳理及发展建议', infor: '2021年，网络货运业务覆盖全国31个省级行政区划单位、333个地级市，区县网络覆盖率接近90%。截至2021年12月31日，全国共有1968家网络货运企业（含分公司），整合社会零散运力360万辆、驾驶员390万人，全年完成运单量6912万单。约占全社会营运货车保有量的17. 4%，比2020年提高了6. 8个百分点，平台集聚效应逐步显现。', content: ``, newsId: 103 },
        // { date: '2022-02-13', img: require('../../assets/img/hang-s2.jpg'), title: '网络货运业态的形成经历了哪三个发展阶段？现行网络货运的典型模式以及税务筹划探讨', infor: '网络货运是数字交通发展中的重要形态，近年来也成为共享出行企业拓展业务布局的重要方向。目前，网络货运业态趋于稳定，市场格局基本明朗，管理政策日趋完善，形成多种典型模式。', content: ``, newsId: 102 },
        // { date: '2022-02-11', img: require('../../assets/img/hang-s1.jpg'), title: '平台经济重磅文件推出 网络货运的几大风口说清楚了！', infor: '网络货运有了顶层设计上的方向指导，能做怎样的调整、优化？最终走向是否会有变化？', content: ``, newsId: 101 }

      ]
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$http.post('api/News/GetNewsList', this.params).then(res => {
        this.newLists = res.data.dataSource.map(item => {
          this.total = res.data.totalCount
          item.date = item.timeAdd
          item.img = item.firstImage
          item.infor = item.abstract
          return item
        })
      })
    },
    JumpNews(id) {
      this.$router.push({
        path: '/baseHome/newscontent',
        query: { newsId: id }
      })
    },
    pageChange(data) {
      this.currentPage = data
      this.params.pageIndex = data
      this.getDataList()
    },
    handleSizeChange(data) {
      this.params.pageSize = data
      this.getDataList()
    }
  }
}
</script>

<style lang="scss">
@import "./../../assets/scss/base.scss";
@import "./../../assets/scss/pages.scss";
.companynews {
  .news-wrapper {
    .news-item {
      &:hover {
        transition: all 0.5s;
        background-color: #0062db;
        .news-date,
        .news-right p,
        .news-right h2 {
          color: #fff !important;
        }
      }
      display: flex;
      width: 100%;
      height: 230px;
      border-radius: 8px;
      margin-bottom: 38px;
      padding: 14px;
      box-sizing: border-box;
      background-color: #fff;
      cursor: pointer;
      .news-content {
        display: flex;
        align-items: center;
        .news-date {
          position: relative;
          color: #888888;
          span:nth-child(1) {
            font-size: 30px;
          }
          span:nth-child(2) {
            display: inline-block;
            width: 1px;
            height: 18px;
            background-color: #888888;
            transform: rotate(30deg);
            margin: 0 6px;
          }
          span:nth-child(3) {
            font-size: 20px;
          }
          span:nth-child(4) {
            position: absolute;
            bottom: -18px;
            left: 20px;
            font-size: 14px;
          }
        }
        .news-line {
          height: 110px;
          width: 1px;
          background-color: #e5e5e5;
          margin: 0 40px;
        }
        .news-right {
          width: 870px;
          h2 {
            margin-bottom: 30px;
            line-height: 30px;
          }
          p {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 30px;
            font-size: 14px;
            color: #888;
          }
        }
      }
    }
    .news-img {
      margin-right: 48px;
    }
  }

  .next-button {
    width: 100%;
    padding-top: 20px;
    margin-bottom: 158px;
    text-align: center;
    .icon-wrap-left {
      display: inline-block;
      margin-right: 30px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 3px solid #0a68dc;
      box-sizing: border-box;
      text-align: center;
      line-height: 56px;
      .el-icon-arrow-left {
        cursor: pointer;
        font-size: 30px;
        color: #0a68dc;
        font-weight: bolder;
      }
    }
    .icon-wrap-right {
      display: inline-block;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 3px solid #0a68dc;
      box-sizing: border-box;
      text-align: center;
      line-height: 56px;
      .el-icon-arrow-right {
        cursor: pointer;
        font-size: 30px;
        color: #0a68dc;
        font-weight: bolder;
      }
    }
  }
}
</style>
